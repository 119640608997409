import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { FaSearch, FaDownload, FaCog, FaStar, FaMapMarkerAlt, FaFilter, FaPhone } from 'react-icons/fa';
import ApiKeyModal from './ApiKeyModal';

const countries = [
  { name: 'United States', code: 'US' },
  { name: 'Mexico', code: 'MX' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'Canada', code: 'CA' },
  { name: 'Australia', code: 'AU' },
  { name: 'Germany', code: 'DE' },
  { name: 'France', code: 'FR' },
  { name: 'Italy', code: 'IT' },
  { name: 'Spain', code: 'ES' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Norway', code: 'NO' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Finland', code: 'FI' },
  { name: 'Ireland', code: 'IE' },
];

const LocalBusinessFinder = () => {
  const [apiKey, setApiKey] = useState('');
  const [showSettings, setShowSettings] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [filters, setFilters] = useState({
    minRating: 0,
    maxRating: 5,
    openNow: false,
    priceLevel: '',
    hasWebsite: false,
    noWebsite: false,
  });

  const [showFilters, setShowFilters] = useState(false);
  const [notification, setNotification] = useState('');

  useEffect(() => {
    const storedApiKey = localStorage.getItem('googleMapsApiKey');
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  const showNotification = (message, type = 'success') => {
    setNotification({ message, type });
    setTimeout(() => setNotification(''), 3000);
  };

  const handleSaveApiKey = async () => {
    localStorage.setItem('googleMapsApiKey', apiKey);
    showNotification('API Key saved successfully!');
  };

  const handleSearch = async () => {
    if (!apiKey) {
      showNotification('Please enter your Google Maps API key.', 'error');
      return;
    }
    if (!keyword || !country || !city) {
      showNotification('Please fill in all fields.', 'error');
      return;
    }

    setLoading(true);
    setError('');
    setResults([]);

    try {
      const searchResponse = await axios.get('/api/places/search', {
        params: { query: `${keyword} in ${city}, ${country}`, key: apiKey }
      });

      if (searchResponse.data.status === 'OK' && searchResponse.data.results) {
        const detailedResults = await Promise.all(
          searchResponse.data.results.map(async (place) => {
            try {
              const detailsResponse = await axios.get('/api/places/details', {
                params: { place_id: place.place_id, key: apiKey }
              });
              return detailsResponse.data.result;
            } catch (detailError) {
              console.error('Error fetching details:', detailError);
              return null;
            }
          })
        );

        setResults(detailedResults.filter(result => result !== null));
      } else {
        setError(`API Error: ${searchResponse.data.status || 'Unknown error'}`);
      }
    } catch (err) {
      console.error('Search error:', err);
      setError('An error occurred while fetching data: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const filteredResults = results.filter(result => (
    (result.rating || 0) >= filters.minRating &&
    (result.rating || 0) <= filters.maxRating &&
    (!filters.openNow || result.opening_hours?.open_now) &&
    (!filters.priceLevel || result.price_level === parseInt(filters.priceLevel)) &&
    ((!filters.hasWebsite && !filters.noWebsite) || (filters.hasWebsite && result.website) || (filters.noWebsite && !result.website))
  ));

  const csvData = filteredResults.map(result => ({
    Name: result.name,
    Address: result.formatted_address,
    Rating: result.rating,
    'Total Reviews': result.user_ratings_total,
    Website: result.website || 'N/A',
    Phone: result.formatted_phone_number || 'N/A',
    'Open Now': result.opening_hours?.open_now ? 'Yes' : 'No',
    'Price Level': result.price_level ? '$'.repeat(result.price_level) : 'N/A',
    Types: result.types?.join(', '),
    'Business Status': result.business_status
  }));

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-md">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-gray-800">Local Business Finder</h1>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
              title="Toggle Filters"
            >
              <FaFilter />
            </button>
            <button
              onClick={() => setShowSettings(!showSettings)}
              className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
              title="API Settings"
            >
              <FaCog />
            </button>
          </div>
        </div>
      </header>
  
      <main className="container mx-auto px-4 py-8">
      {showSettings && (
  <ApiKeyModal
    isOpen={showSettings}
    onClose={() => setShowSettings(false)}
    apiKey={apiKey}
    setApiKey={setApiKey}
    onSave={handleSaveApiKey}
  />
)}
  
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <input
              type="text"
              className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black"
              placeholder="Enter keyword (e.g., plumber)"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <select
              className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              <option value="">Select Country</option>
              {countries.map((c) => (
                <option key={c.code} value={c.name}>{c.name}</option>
              ))}
            </select>
            <input
              type="text"
              className="p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black"
              placeholder="Enter city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <button 
              className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center justify-center"
              onClick={handleSearch}
            >
              <FaSearch className="mr-2" />
              Search
            </button>
          </div>
          {notification && (
            <div className={`mb-4 p-3 rounded-md ${notification.type === 'error' ? 'bg-red-100 text-red-500' : 'bg-green-100 text-green-500'}`}>
              {notification.message}
            </div>
          )}
          {error && <div className="text-red-500 mb-4 p-3 bg-red-100 rounded-md">{error}</div>}
          {loading && <div className="text-blue-500 mb-4 p-3 bg-blue-100 rounded-md">Loading...</div>}
        </div>
  
        {showFilters && (
          <div className="bg-white rounded-lg shadow-md p-6 mb-8">
            <h3 className="text-lg font-semibold mb-4">Filters</h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label htmlFor="minRating" className="block text-sm font-medium text-gray-700 mb-1">Min Rating:</label>
                <input
                  type="number"
                  id="minRating"
                  min="0"
                  max="5"
                  step="0.1"
                  value={filters.minRating}
                  onChange={(e) => setFilters({...filters, minRating: parseFloat(e.target.value)})}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black"
                />
              </div>
              <div>
              <label htmlFor="maxRating" className="block text-sm font-medium text-gray-700 mb-1">Max Rating:</label>
                <input
                  type="number"
                  id="maxRating"
                  min="0"
                  max="5"
                  step="0.1"
                  value={filters.maxRating}
                  onChange={(e) => setFilters({...filters, maxRating: parseFloat(e.target.value)})}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black"
                />
              </div>
              <div>
                <label htmlFor="priceLevel" className="block text-sm font-medium text-gray-700 mb-1">Price Level:</label>
                <select
                  id="priceLevel"
                  value={filters.priceLevel}
                  onChange={(e) => setFilters({...filters, priceLevel: e.target.value})}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent text-black"
                >
                  <option value="">All</option>
                  <option value="1">$</option>
                  <option value="2">$$</option>
                  <option value="3">$$$</option>
                  <option value="4">$$$$</option>
                </select>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="openNow"
                  checked={filters.openNow}
                  onChange={(e) => setFilters({...filters, openNow: e.target.checked})}
                  className="form-checkbox h-5 w-5 text-blue-600 mr-2"
                />
                <label htmlFor="openNow" className="text-sm font-medium text-gray-700">Open Now</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="hasWebsite"
                  checked={filters.hasWebsite}
                  onChange={(e) => setFilters({...filters, hasWebsite: e.target.checked, noWebsite: false})}
                  className="form-checkbox h-5 w-5 text-blue-600 mr-2"
                />
                <label htmlFor="hasWebsite" className="text-sm font-medium text-gray-700">Has Website</label>
              </div>
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="noWebsite"
                  checked={filters.noWebsite}
                  onChange={(e) => setFilters({...filters, noWebsite: e.target.checked, hasWebsite: false})}
                  className="form-checkbox h-5 w-5 text-blue-600 mr-2"
                />
                <label htmlFor="noWebsite" className="text-sm font-medium text-gray-700">Don't Have Website</label>
              </div>
            </div>
          </div>
        )}
  
        {results.length > 0 && (
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-gray-800">Search Results</h2>
              <CSVLink
                data={csvData}
                filename={"business_results.csv"}
                className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 transition duration-200 ease-in-out flex items-center text-sm"
              >
                <FaDownload className="mr-2" />
                Export CSV
              </CSVLink>
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Business</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rating</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Website</th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredResults.map((result, index) => (
                    <tr key={index} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-normal">
                        <div className="text-sm font-medium text-gray-900">{result.name}</div>
                        <div className="text-sm text-gray-500">{result.formatted_address}</div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <FaStar className="text-yellow-400 mr-1" />
                          <span className="text-sm text-gray-900">{result.rating ? result.rating.toFixed(1) : 'N/A'}</span>
                          <span className="text-sm text-gray-500 ml-1">({result.user_ratings_total || 0})</span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-900">{result.price_level ? '$'.repeat(result.price_level) : 'N/A'}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${result.opening_hours?.open_now ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                          {result.opening_hours?.open_now ? 'Open' : 'Closed'}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          <FaPhone className="inline mr-1" />
                          {result.formatted_phone_number || 'N/A'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {result.website ? (
                          <a href={result.website} target="_blank" rel="noopener noreferrer" className="text-sm text-blue-600 hover:underline">
                            Website
                          </a>
                        ) : (
                          <span className="text-sm text-gray-500">N/A</span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(result.name)}&query_place_id=${result.place_id}`} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">
                          <FaMapMarkerAlt className="inline mr-1" /> Map
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default LocalBusinessFinder;