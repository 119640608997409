import React from 'react';
import { ChevronLeft, ExternalLink, Key, Shield, Map } from 'lucide-react';

const APIKeyInstructions = () => {
  const steps = [
    { text: "Go to the Google Cloud Console.", link: "https://console.cloud.google.com/" },
    "Create a new project or select an existing one.",
    "Navigate to the 'APIs & Services' dashboard.",
    "Click on '+ ENABLE APIS AND SERVICES' at the top of the page.",
    "Search for and enable the following APIs:",
    {
      text: "Enable required APIs:",
      subSteps: [
        "Maps JavaScript API",
        "Places API",
        "Geocoding API"
      ]
    },
    "Once enabled, go to the 'Credentials' page.",
    "Click 'Create Credentials' and select 'API Key'.",
    "Your new API key will be displayed. Copy this key.",
    "For security, restrict the API key to only the services you need (Maps JavaScript API, Places API, and Geocoding API)."
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-xl overflow-hidden">
        <div className="px-6 py-8 sm:p-10">
          <h1 className="text-3xl font-bold text-gray-900 mb-8 flex items-center">
            <Map className="w-8 h-8 mr-3 text-blue-600" />
            Setting Up Google Maps APIs
          </h1>
          
          <ol className="space-y-6 text-gray-700">
            {steps.map((step, index) => (
              <li key={index} className="flex items-start">
                <span className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold mr-4 flex-shrink-0">
                  {index + 1}
                </span>
                <div className="pt-1">
                  {typeof step === 'string' ? (
                    step
                  ) : step.subSteps ? (
                    <>
                      <p>{step.text}</p>
                      <ul className="list-disc list-inside ml-4 mt-2 space-y-1">
                        {step.subSteps.map((subStep, subIndex) => (
                          <li key={subIndex} className="text-gray-600">{subStep}</li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      {step.text}{' '}
                      <a 
                        href={step.link} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="text-blue-600 hover:text-blue-800 inline-flex items-center"
                      >
                        Visit <ExternalLink className="w-4 h-4 ml-1" />
                      </a>
                    </>
                  )}
                </div>
              </li>
            ))}
          </ol>

          <div className="mt-10 p-5 bg-yellow-50 border border-yellow-100 rounded-lg flex items-start">
            <Shield className="w-6 h-6 text-yellow-500 mr-4 flex-shrink-0" />
            <p className="text-yellow-700">
              <strong className="font-semibold">Security Note:</strong> Keep your API key confidential and never share it publicly. If you suspect your key has been compromised, you can always regenerate it in the Google Cloud Console.
            </p>
          </div>

          <div className="mt-10 flex items-center justify-between">
            <a href="/" className="text-blue-600 hover:text-blue-800 inline-flex items-center group">
              <ChevronLeft className="w-5 h-5 mr-1 transform group-hover:-translate-x-1 transition-transform" />
              Back to Local Business Finder
            </a>
            <Key className="w-6 h-6 text-gray-400" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default APIKeyInstructions;