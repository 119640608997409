import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LocalBusinessFinder from './Components/LocalBusinessFinder/LocalBusinessFinder';
import APIKeyInstructions from './Components/APIKeyInstructions';
import './index.css';

function App() {
  return (
    <Router>
      <div className="text-center min-h-screen bg-[#282c34] text-white">
        <Routes>
          <Route path="/" element={<LocalBusinessFinder />} />
          <Route path="/api-instructions" element={<APIKeyInstructions />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;